import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Hero from './Hero';

const ImageBreaker1 = () => {
  return (
    <StaticQuery
      query={graphql`
        query ImageBreakerQuery {
          file(relativePath: { eq: "United2.jpg" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 1400, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
      <div className="worldwide imagebreaker">
      <Hero
        fluid={data.file.childImageSharp.fluid}
        posY="10%"
        posX="70%"
        height="50vh"
        width="100%"
        />
        <div className="flex center column basePad">
          <h2>About United O7</h2>
          <p>Two experienced, internationally renowed players in the heavy sea transportation market have joined forces: With a strategic collaboration, United Heavy Lift and Ocean7 Projects cover all market segments of the heavy lift industry, offering clients an even larger and more diverse total fleet and providing their combined expertise and experience. Working in a large agency network is about providing a seamless response to clients with complex projects. The joint service aims to increase transportation efficiency by reducing the number of vessels needed and the number of individual trips required. We can offer a wide range of sea transportation solutions in one coordinated action.</p>
          <p>The two ocean transport specialists joined forces in 2019 and have since opened offices in Kuala Lumpur, Beijing, Tokyo, Singapore, Dubai, Houston and Italy under the United O7 brand.</p>
          </div>
        </div>
      )
    }
  />
)}
export default ImageBreaker1;
